import {
  Box,
  Button,
  Card,
  Grid,
  Link, Modal,
  Typography
} from "@mui/material";
import {useLoaderData} from "react-router-dom";
import React, {useState} from "react";
import {modalStyle} from "../../utils/urlsTabsHelper";
import PreviewIcon from "@mui/icons-material/Preview";
import UrlsTable from "./components/UrlsTable";
import ForbiddenAlert from "../../components/ForbiddenAlert";

const UrlsTab = () => {
  const { artwork_url, artwork_name, distributor_id, test_link } = useLoaderData();
  const pendingCampaignsSearchOptions = { status: "pending"};
  const liveCampaignsSearchOptions = { status: "live"};
  const [artWorkModalOpen, setArtWorkModalOpen] = useState(false);
  const [refreshPendingKey, setRefreshPendingKey] = useState(0);
  const [refreshOthersKey, setRefreshOthersKey] = useState(0);
  const [enableEmailButton, setEnableEmailButton] = useState(false);
  const [showForbiddenAlert, setShowForbiddenAlert] = useState(false);

  const refreshPendingUrls = () => {
    setRefreshPendingKey(prevKey => prevKey + 1);
    setRefreshOthersKey(prevKey => prevKey + 1);
  }
  const handleCloseArtWorkModal = () => {
    setArtWorkModalOpen(false);
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      sx={{height: "100%"}}>
      <Grid item sx={{ pb: 3 }}>
        <Typography variant="h6">Testing Suite</Typography>
        <Card>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Distributor ID</Typography>
              {distributor_id}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Test Link</Typography>
              <a href={test_link} rel="noreferrer" target="_blank">Click Here</a>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item>
        <Typography variant="h6">Creatives</Typography>
        <Card>
          <Grid container sx={{ p: 2 }}>
            <Grid item sx={{ pr: 1 }}>
              <Typography variant="subtitle2">Artwork</Typography>
              {artwork_name ?? "N/A"}
            </Grid>
            {artwork_url &&
              (<Grid item sx={{ display: "flex", alignItems: "flex-end" }}>
                <PreviewIcon color="primary" fontSize="small" /><Link rel="noopener" href="#" onClick={(e) => setArtWorkModalOpen(true)} >Preview</Link>
            </Grid>) }
          </Grid>
        </Card>
      </Grid>
      <Grid item sx={{ pt: 5 }}>
        <UrlsTable
          searchOptions={pendingCampaignsSearchOptions}
          refreshMethod={refreshPendingUrls}
          refreshFlag={refreshPendingKey}
          enableEmailButtonMethod={setEnableEmailButton}
          onForbiddenError={() => setShowForbiddenAlert(true)}
        />
      </Grid>
      <Grid item>
        <UrlsTable
          searchOptions={liveCampaignsSearchOptions}
          refreshMethod={refreshPendingUrls}
          refreshFlag={refreshOthersKey}
          enableEmailButtonVar={enableEmailButton}
          key={refreshOthersKey}
          onForbiddenError={() => setShowForbiddenAlert(true)}
        />
      </Grid>
      <ForbiddenAlert open={showForbiddenAlert} onClose={() => setShowForbiddenAlert(false)} />
      <Modal
        open={artWorkModalOpen}
        onClose={handleCloseArtWorkModal}
        closeAfterTransition>
        <Box sx={modalStyle} alignItems="center">
          <Grid container>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <img  alt="Podcast Artwork preview" src={artwork_url} style={{ maxHeight: "90%", maxWidth: "90%" }}/>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button variant="text" onClick={handleCloseArtWorkModal}>CLOSE</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default UrlsTab;
